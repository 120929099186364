<template>
    <div class="org-user">
        <div class="btn-groups">
            <div class="el_right generalQuery">
                <el-input
                    placeholder="请输入用户名"
                    v-model="searchKey"
                    class="input-with-select"
                    @keydown.native.enter="getUserList"
                >
                    <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
                </el-input>
            </div>
            <el-button
                class="add-role"
                type="primary"
                icon="el-icon-plus"
                @click="handleAdd"
            >
                新增用户
            </el-button>
        </div>
        <el-table
            border
            stripe
            :data="tableList"
            ref="multipleTable"
            :highlight-current-row="true"
            v-loading="loading"
            height="calc(100% - 0.35rem)"
        >
            <el-table-column
                label="序号"
                type="index"
                width="80"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="用户名"
                prop="User_Name"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="登录名称"
                prop="Login_Name"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="手机号"
                prop="Mobile"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="账号类型"
                prop="User_Type"
                align="center"
            >
                <template slot-scope="scope">
                    {{ scope.row.User_Type|getUserType }}
                </template>
            </el-table-column>
            <el-table-column
                label="描述"
                prop="Desc"
                align="center"
                show-overflow-tooltip
                width="120"
            >
            </el-table-column>
            <el-table-column
                label="登录时间"
                prop="Last_Login_Time"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="登录次数"
                prop="Login_count"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="操作"
                width="200"
                align="center"
            >
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="修改"
                        placement="top"
                    >
                        <i class="iconfont iconxiugai" @click="handleEdit(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="重置密码"
                        placement="top"
                    >
                        <i class="iconfont iconmima1" @click="handleResetPwd(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="top"
                    >
                        <i class="iconfont iconlajitong" @click="handleDel(scope.row)"></i>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <div class="table-page">
            <div class="el_right">
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[20,40,60,80,100]"
                    :page-size="limit"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog
            :visible.sync="showEditDialog"
            :title="editTitle"
            width="10rem"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
        >
            <userFrom
                v-if="showEditDialog"
                :user-id="curUser.User_Id"
                :edit-type="editType"
                @handleSucc="handleSucc"
                @handleClose="handleClose"
            ></userFrom>
        </el-dialog>
    </div>
</template>

<script>
// import userEdit from './userEdit';
import userFrom from './newUserFrom';
export default {
    name: 'org-role',
    components: { userFrom },
    data() {
        return {
            loading: false,
            tableList: [],
            dialogTitle: '',
            dialogFormVisible: false,
            searchKey: '',
            page: 1,
            limit: 20,
            total: 0,

            curUser: {},
            editType: '',
            showEditDialog: false,
            editTitle: '',
        };
    },
    filters: {
        getUserType(type) {
            const obj = {
                1: '内部用户',
                2: '司机',
                3: '客户',
                4: '供应商',
            };
            return obj[type] || '';
        },
    },
    created() {
        this.getUserList();
    },
    methods: {
        // 获取组织列表
        async getUserList() {
            this.loading = true;
            const data = await this.$axios.get('/interfaceApi/basicplatform/users/petpagebysecondadmin?'
                + `pageindex=${this.page}&pagesize=${this.limit}&search=${this.searchKey}`);
            this.tableList = data.List;
            this.total = data.PageCount;
            this.loading = false;
        },

        // 用户新增
        handleAdd() {
            this.editTitle = '用户管理添加';
            this.editType = 'newUser';
            this.curUser = { User_Id: '' };
            this.showEditDialog = true;
        },

        // 用户编辑
        handleEdit(row) {
            this.editTitle = '用户管理编辑';
            this.editType = 'editUser';
            this.curUser = row;
            this.showEditDialog = true;
        },
        // 重置密码
        handleResetPwd(item) {
            this.$confirm('确认当前用户密码?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios.put('/interfaceApi/platform/user_manage/reset_passwords/' + item.User_Id)
                    .then(() => {
                        this.$message.success('重置成功');
                    }).catch(e => this.$message(e.ErrorCode.Message));
            });
        },
        // 删除角色
        handleDel(item) {
            this.$confirm('确认删除当前用户?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                const UserIds = [item.User_Id];
                const TenantId = this.$takeTokenParameters('TenantId');
                const deleteDate = {
                    TenantId,
                    UserIds,
                };
                const res = await this.deleteUserRequst(deleteDate);
                if (res) {
                    this.$message({
                        showClose: true,
                        message: '删除成功！',
                        type: 'success',
                    });
                    this.getUserList();
                }
            });
        },
        handleSucc() {
            this.handleClose();
            this.getUserList();
        },
        handleClose() {
            this.showEditDialog = false;
        },
        // 设置每页条数
        handleSizeChange(val) {
            this.limit = val;
            this.getUserList();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.page = val;
            this.getUserList();
        },

        // 删除用户请求
        deleteUserRequst(deleteDate) {
            const url = '/interfaceApi/platform/tenant_user_manage/delete';
            return this.$axios.$delete(url, deleteDate, { defEx: true });
        },
    },
};
</script>

<style lang="stylus" scoped>
    .org-user
        position relative
        height 100%;
        padding 0.1rem;
        background #fff;
        .btn-groups
            position: absolute;
            right: 0;
            top: -0.46rem;
            height: 0.4rem;
            display flex
            align-items center
            > *
                margin 0 0.2rem;
            .generalQuery
                height 100%;
                margin: 0.05rem 0
                >>> .el-input
                    height 100%;
                    .el-input__inner
                        width: 3rem;
                        height 100%;
                        font-size: .14rem;
                    .el-input-group__append
                        border: 1px solid #1577D2;
                        padding: 0 .1rem;
                        overflow: hidden;
                        width: .6rem;
                        font-size: .2rem;
                        text-align: center;
                        color #fff
                        .el-button
                            background: #1577D2;
                .el-input__inner
                    width 3rem
                    font-size 0.14rem
                .el-input-group__append
                    overflow hidden
                    width 0.6rem
                    font-size 0.2rem
                    text-align center
            .add-role
                padding 0
                width: 1.22rem;
                height: 0.4rem;
                background: #1577D2;
                border-radius: 0.02rem;
                font-size 0.16rem;
                color #fff;
                z-index: 2;
        .el-table
            .iconfont
                padding 0 0.1rem;
                cursor pointer
            .iconsuo:hover
                color #21BC9A
            .iconbumen:hover
                color #FF6600
            .iconxiugai:hover
                color #1577D2
            .iconlajitong:hover
                color #D60110
            >>> th
                background: #EDF0F5;
                border: 1px solid #DEDFE0;
                color #022782
            th,td
                padding 0

</style>
